$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/kultour/';
@import 'styles/helpers/global_variables';

.outer {
  @include travel-types-block-outer;
}

.root {
  @include content-block;

  .card {
    margin-bottom: $travel-types-card-margin-bottom;
    border: none;
    box-shadow: rgb(0 0 0 / 20%) 0 0 1px -2px, rgb(0 0 0 / 14%) 0 0 2px 0, rgb(0 0 0 / 12%) 0 0 5px 0;

    @include card-with-image-left;

    &.right {
      @include card-with-image-right;

      .ribbon {
        left: 0;
      }
    }
  }

  .imageWrap {
    position: relative;

    &.left {
      @include card-image-left;
    }

    &.right {
      @include card-image-right;
    }
  }

  .body {
    padding-bottom: 6.5rem !important;
    @include card-body;
  }

  $ribbonBgColor: $primary;
  $ribbonHoverBgColor: $signal;

  .ribbon {
    @include branded-button-font;

    // position
    position: absolute;
    bottom: 23px;
    left: 0;

    // no left side shadow
    overflow: hidden;
    padding: 5px 27px 6px 0;

    @include media-breakpoint-up(lg) {
      left: 50%;
    }
  }

  @include media-breakpoint-up(lg) {
    .imgWrap {
      & > img {
        position: absolute;
        object-fit: cover;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1);
        height: 100%;
      }
    }
  }
}
